import React from "react"
import {Carousel, HomeMessage, Services, Location, Reservation, Reviews} from '../components'
import SEO from '../components/seo'

const IndexPage = () => {
  return (
    <>
      <SEO title="Home" />
      <Reservation />
      <Carousel />
      <Reviews />
      <HomeMessage />
      <Services />
      <Location />
    </>
  );
}

export default IndexPage